
  export default {
    name: "BlockTitle",
    props: [
      "text",
      "link_label",
      "link_url",
      "classe"
    ]
  }
